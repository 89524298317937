import React, {useState} from 'react';
import { useMoveBack } from "../hooks/useMoveBack";
import CocktailTokenLogo from '../pictures/cryptobartl_logo.png';
import { NavComponent } from '../navigationComponents/NavComponent';

function CryptoCocktailParty()
{
    const moveBack = useMoveBack();

    return (
        <main >
            <div id="divHomepage" className='comingSoonDesign'>
            <NavComponent/>
                
            <div className='homepageDesign'>  
                <div  style={{textAlign: "center"}}>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                    <h1 style={{textAlign: "center"}}> The Crypto Cocktail Party is launched!</h1>
                    <br/>
                    <br/>
                    <img src={CocktailTokenLogo} alt="Cocktail token image" height = {200} width = {200} />
                    <br/>
                    <br/>
                    <h3 style={{textAlign: "center"}}>You can purchase the CocktailToken over</h3>
                    <a href="https://crypto-cocktailparty.com/Startpage">https://crypto-cocktailparty.com/Startpage</a>
                    <br/>
                    <br/>
                </div>

                <br/>
                <br/>

                <button onClick={moveBack} size="large">
                    &larr; Go back
                </button>

                <br/>
                <br/>
                <br/>

                <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
                <p style={{textAlign: "center"}}>Copyright © 2025 CryptoBartl</p>

            </div>
            </div>  
        </main>
    )
}

export default CryptoCocktailParty;