import React, {useState} from 'react';
import { useMoveBack } from "../hooks/useMoveBack";
import { NavComponent } from '../navigationComponents/NavComponent';

function CryGIA_Marketplace()
{

    const moveBack = useMoveBack();

    return (
        <main >
            <div id="divHomepage" className='homepageDesign'>
            <NavComponent/>
            
            <br/>
            <div  className='ComingSoonDesign'>
            <div  style={{textAlign: "center"}}>
                    <h1 style={{textAlign: "center"}}> The CryGIA NFT Marketplace is coming soon!</h1>
                    <h2 style={{textAlign: "center"}}> The CryGIA Page is under construction.</h2>
                    <h3 style={{textAlign: "center"}}>The CryGIA NFT Marketplace is expected to be launched in 2025. Follow us to get the latest updates!</h3>
                </div>

                <button onClick={moveBack} size="large">
                    &larr; Go back
                </button>

                <br/>
                <br/>
                <br/>

                <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
                <p style={{textAlign: "center"}}>Copyright © 2025 CryptoBartl</p>

            </div>
            </div>
        </main>
    )
}

export default CryGIA_Marketplace;