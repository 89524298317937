import React, {useState, useRef, useEffect} from 'react';  
import YoutubeEmbed from "../YoutubeEmbed";
import { NavComponent } from '../navigationComponents/NavComponent';

function UsemoveTop() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
}

function CryptoWorld()
{
    const refScrollUp = useRef();

    return (
        <main>
            <div id="divHomepage" className='homepageDesign'>
            
            <NavComponent/>
            

        <div id="UnderstandCryptoDIV">
        <h1 className="factoryTitle"> Crypto World </h1>

        <div id="internalNav" className="intpageNavigation">
            <ul className='intpageNavigation'>
                <li className='intpageNavigation'> <a href="#Blockchain" className='intpageNavigation'> #Blockchain</a></li>
                <li className='intpageNavigation'> <a href="#Coin" className='intpageNavigation'> #Coin</a></li>
                <li className='intpageNavigation'> <a href="#Ethereum" className='intpageNavigation'> #Ethereum</a></li>
                <li className='intpageNavigation'> <a href="#Token" className='intpageNavigation'> #Token</a></li>
                <li className='intpageNavigation'> <a href="#SmartContracts" className='intpageNavigation'> #Smart Contracts</a></li>
                <li className='intpageNavigation'> <a href="#DeFi and dApps" className='intpageNavigation'> #DeFi and dApps</a></li>
                <li className='intpageNavigation'> <a href="#CEX and DEX" className='intpageNavigation'> #CEX and DEX</a></li>
            </ul>
        </div>
        <div style={{ borderTop: "2px solid #333 ", marginLeft: 20, marginRight: 20 }}></div>
        <div ref={refScrollUp}> </div>
        <div  className="factoriesDesign">
              <div id="Blockchain">
                    <h2>Blockchain</h2>
                    <p>A blockchain contains a permanent and public audit trail of who holds your tokens. 
                      It's like a database of customers, but instead of email addresses, you have a database 
                      of wallet addresses. You can always drop rewards, to remind them of the product!</p>
                      
                    <YoutubeEmbed className="video-frame" embedId= "https://www.youtube.com/embed/V9bayGrmqnI?si=75aw3Fh0nMJqWFVs"/>
                    
                    <br/>
                    <br/>
              </div>
              <div id="Coin">
                  <h2>Coin</h2>
                  <p>Coin has its own blockchain while a token is build on an already existing network.
                    Cryptocurrencies in general rely on the blockchains for security and decentralization reasons.
                    Coins on their blockchain act as a native coin, as well as a network fee payment coin (gas fee).
                  </p>
                  <p>Native coins are the inherent currency of blockchains. Each independent blockchain has its own cryptocurrency, which is used to reward miners and validators who verify
                    transactions, and also serve as a currency for paying network fees. They are also called base coins, or internal coins. Are used to represent the value of an ecosystem.
                  </p>
                  <p>Ethereum - Cryptocurrency is Ether (ETH). </p>
                  <p>Bitcoin - BTC</p>
                  <p>Stellar - XML</p>
                  <p>Solana - SOL</p>
                  <YoutubeEmbed className="video-frame" embedId= "https://player.vimeo.com/video/266675226?h=a462899a62"/>
              </div>
              <br/>
              <br/>
              
              <div id="Ethereum">
                  <h2>Ethereum</h2>
                  <p>Ethereum as a blockchain with a digital currency caller Ether (ETH) was created 2014, as an open-source platform
                    for running decentralized applications (dApps). Ethereum was prompted by Bitcoin's inflexibility and inability to run applications
                    on its blockchain. We are facing a growing industry of decentralized applications and smart contracts.
                  </p>
                  <p>ERC = Ethereum Request for Command. This is the documentation that defines the standards for programming in Ethereum. 
                  </p>
                  <p> Smart Contracts are built from code written in the programming language called Solidity. This code is then compiled into ABI code
                    and deployed on the blockchain. Smart contracts allow us to create digital contracts. 
                    These digital contracts work similarly to real-world contracts, allowing 2 or more parties to transact, in this case on the blockchain.
                    A smart contract is also a kind of account. It has a balance, but unlike a regular wallet address, it is not controlled by the user, but
                    by the code and functions.
                  </p>
                  <br/>
                  <YoutubeEmbed className="video-frame" embedId= "https://www.youtube.com/embed/1Hu8lzoi0Tw?si=2P7iwKxXitTlmehC"/>
                  <br/>
                  <br/>
                  <p>My favorite website for lerning more about Ethereum:    
                    <a href="https://ethereum.org/en/" style={{textAlign:'left'}}>https://ethereum.org/en/</a>
                  </p>
              </div>
              <br/>
              <br/>
              <div id="Token">
                  <h2>Token</h2>
                  <p>Crypto tokens are digital assets that are built on another cryptocurrency's blockchain. 
                    A blockchain is a digital ledger that stores information in blocks that are linked. 
                    This information can be transaction records or full-fledged programs that operate on the blockchain, 
                    which are called smart contracts.</p>
                  <p>Tokens are created on an existing blockchain and at the same time are not native to the blockchain they are built on. 
                    E.g. SHIBA INU (SHIB) is a token on the Etherium network (where the native coin is ETH). 
                    To create a new token, a given template of a network has to be followed (such as ERC-20).
                  </p>
                  <p>ERC-20 Ethereum based tokens are not mineable! In this case, they are minted.
                    (Mining = giving rewards in exchange of computing power)
                    With ERC-20 tokens, the supply is set when the contract is created. The tokens are minted at launch and further down the line, the developers
                    distribute the supply as they wish.
                  </p>
                  
                  <p>Here you can see as example the most known lists of tokens:
                    <br/>
                    <br/>
                    Tokens on Ethereum network: <a href="https://etherscan.io/tokens" style={{textAlign:'left'}}>https://etherscan.io/tokens</a>
                    <br/>
                    Tokens on Binance network: <a href="https://www.binance.com/en/leveraged-tokens/tokens/allTokens" style={{textAlign:'left'}}>https://www.binance.com/en/leveraged-tokens/tokens/allTokens</a>
                  </p> 
                  <br/>
                  <br/>
                  <YoutubeEmbed className="video-frame" embedId= "https://www.youtube.com/embed/fZpf0CVoeKI?si=hqMDl9b-ncGZZiTX"/>
                  <br/>
                  <br/>
              </div>
              <br/>
              <br/>
              <div id="SmartContracts">
                  <h2>Smart Contracts</h2>
                  <p>The smart contracts are like any usual contract, but issued by computers. The smart contracts are built from code written in 
                    Solidity language. This code is compiled into ABI code and deployed on the blockchain.
                  </p>
                  <p>Smart contracts allow us to create digital contracts. These digital contracts work similarly to real-world contracts,
                    allowing 2 or more parties to transact, in this case on the blockchain. A smart contract is also a kind of account. That is, it a 
                    balance, but unlike a regular wallet address, it is not controlled by the user but by code and functions.
                  </p> 
                  <br/>
                  <br/>
                  <YoutubeEmbed className="video-frame" embedId= "https://www.youtube.com/embed/pyaIppMhuic?si=wVIAoxLt9Vkp47XK"/>
                  <br/>
                  <br/>
              </div>

              <br/>
              <br/>

              <div id="DeFi and dApps">
                  <h2>DeFi</h2>
                  <p>DeFi = decentralized finance. Is basically the back of crypto world.
                  </p>
                  <p>Refers to an ecosystem consisting of financial applications that are developed on decentralized blockchain systems. 
                    DeFi challenges traditional finance by eliminating the need for banks and intermediaries altogether.
                  </p> 
                  <br/>
                  <h2>dApps</h2>
                  <p>dApp = decentralized application. 
                  </p>
                  <p>dApps are built on blockchain technology to create more transparent and secure systems. Regular apps are usually controlled
                    and operated by a central entity, such as company or organization. dApps operate autonomosly, relying on
                    blockchain nodes or smart contract logic.
                  </p> 
                  <p>E.g. 1: Uniswap on ETH: open source decentralized exchange (DEX) for trading and providing liquidity for ERC20 tokens on
                    Ethereum. It serves as automated marked maker (AMM) for exchanging ERC20 tokens. This is done through a set of smart contracts
                    that determine the creation of liquidity pools and the exchange of assets.
                  </p> 
                  <p>E.g. 2: OpenSea: supports multiple blockchains, like Matic, which is very cheap in terms of network fees.
                  </p> 
                  <br/>
                  <br/>
              </div>

              <div id="CEX and DEX">
                  <h2>CEX</h2>
                  <p>CEX = Centralized Exchange
                  </p>
                  <p>CES is very user friendly and ideal for the people without much crypto knowledge. It usually involves higher fees. Most popular CEX:
                    Coinbase, Binance, ByBit, Gate.io
                  </p> 
                  <br/>
                  <h2>DEX</h2>
                  <p>DEX = Decentralized Exchange. Here you can list your tokens for trading.
                  </p>
                  <p>Trading takes place directly between:
                    <ul>
                      <li>the wallets of two users</li>
                      <li>between a user and a pool of liquidity with limited or excluded input from a 3rd party</li>
                    </ul>
                  </p>
                  <p>In most cases, there is no need of depositing or withdrawing of cryptocurrencies. On DEX, no one checks who you are. All you need is 
                    a wallet. DEX does not store the locked funds (for example, during staking) are held by a liquidity pool or smart contract, according to
                    the logic written in the contract code, which is publicly available.
                  </p> 
                  <p>Since it operates on blockchains, gas fees are to be considered.
                  </p>
                  <br/>
                  <p>Most known DEX:
                    <ul>
                      <li>on Avalance: TraderJoe, Pangolin</li>
                      <li>on Matic: QuickSwap</li>
                      <li>on BSC, ETH, Polygon and Aptos: PancakeSwap</li>
                    </ul>
                  </p> 
                  <br/>
                  <YoutubeEmbed className="video-frame" embedId= "https://www.youtube.com/embed/7pRkeHgxig0?si=gCk3uz7yNH1OtWm-"/>
                  <br/>
                  <br/>
                  <p style={{fontStyle:'italic', fontSize:12}}>Main source of information:   Boron Piotr. Your own token. 2023 </p>
              </div>

              <br/>
              <br/>

              <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
                <p style={{textAlign: "center"}}>Copyright © 2025 CryptoBartl</p>
              </div>

            </div>
          
        </div>
        </main>
    )
}

export default CryptoWorld;