import React, {useState} from 'react';
import {
    HiArrowDown
  } from "react-icons/hi2";
  import { FaFilePdf } from "react-icons/fa6";
  
  import { useMoveBack } from "../hooks/useMoveBack";
  import oldtimerLogo from '../pictures/OldTimerAILogo.jpeg';

  import styled from 'styled-components';

import { NavComponent } from '../navigationComponents/NavComponent';

import pdfEN from '../requestForm/NFTFormularENG.pdf'
import pdfDE from '../requestForm/NFTFormularDE.pdf'
//{process.env.PUBLIC_URL + 'src/requestForm/NFTFormularDE.pdf'}


const StyledDiv = styled.div`
margin: auto;
padding: 15px;
width: fit-content;
border-radius: 20px;
outline: none;
border: transparent;
color: white;
font-family: Verdana, Geneva, Tahoma, sans-serif;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

function ProjectOldtimerNFT()
{
    //for collapsing
    const [openNavigationBar, setOPenNavigationBar] = useState(true);
    const toggle_NavigationBar = () => {
        setOPenNavigationBar(!openNavigationBar);
    };

    const [openRequestText, setOPenRequestText] = useState(true);
    const toggle_RequestText = () => {
        setOPenRequestText(!openRequestText);
    };

    const moveBack = useMoveBack();

    return (
        <main >
            <div id="divHomepage" className='comingSoonDesign'>
            <NavComponent/>
                
            <div className='homepageDesign'>  
                <div  style={{textAlign: "center"}}>
                <br/>
                <br/>
                <br/>
                <br/>
                    <h1 style={{textAlign: "center"}}> The Oldtimer NFT Collection is launched on OpenSea!</h1>
                    <br/>
                    <br/>
                    <StyledDiv>
                    <img src={oldtimerLogo} alt="OldtimerNFTLogo" height = {200} width = {200} />
                    </StyledDiv>
                    <br/>
                    <br/>
                    <h3 style={{textAlign: "center"}}>You can visit the OldtimerNFTCollection over</h3>
                    <a href="https://opensea.io/collection/oldtimernftcollection">https://opensea.io/collection/oldtimernftcollection</a>
                    <br/>
                    <br/>
                </div>
                <br/>
                <h2 style={{textAlign: "center"}}> You want your Oldtimer to become part of the Collection?   <button onClick={toggle_RequestText}>  <HiArrowDown/></button></h2>
                
                {openRequestText && (
                    <div className="factoriesDesign">
                        <br/>
                    <div>
                        <text>Want to give your oldtimer the new</text>
                        <text style={{fontWeight: "bold"}}> high-tech flair</text>
                        <text>?</text>
                    </div> 
                    <br/>
                    <div>
                        <text>Turn it into</text>
                        <text style={{fontWeight: "bold"}}>  digital art</text>
                        <text>!</text>
                    </div>
                    <br/> 
                    <br/>
                    <div>
                        <text>Let it become forever stored on the blockchain, for only</text>
                        <text style={{fontWeight: "bold"}}> 250€</text>
                        <text>!</text>
                    </div>
                    <br/>
                    <br/>
                    <p style={{textAlign: "left"}}>
                    Apply for an Oltimer NFT, by replying to this email with the necessary information - Note: only the first 80 applications will be accepted.
                    Send us a picture of your car in the attachment, and the data which shall be stored among on the blockchain. Make it public!</p>
                    
                    <br/>
                    <p style={{textAlign: "left"}}><text style={{fontWeight: "bold"}}>Your application - MUST HAVE's:</text>
                    <ul style={{textAlign: "left"}}>
                        <li><text>Description:</text> <text style={{fontStyle: "italic", fontSize: "smaller"}}>- e.g. The perfect oldtimer one could imagine! Perfect shape, kept beloved as family treasure!</text></li>
                        <li><text>Brand:</text> - <text style={{fontStyle: "italic", fontSize: "smaller"}}>e.g. Porsche</text></li>
                        <li><text>Model:</text> - <text style={{fontStyle: "italic", fontSize: "smaller"}}>e.g. 928</text></li>
                        <li><text>Year:</text> - <text style={{fontStyle: "italic", fontSize: "smaller"}}>e.g. 1980</text></li>
                        <li>VIN: -</li>
                    </ul>
                    </p>
                    <p style={{textAlign: "left"}}><text style={{fontWeight: "bold"}}>Your application - optional's:</text>
                        <ul>
                            <li>Owner: -</li>
                            <li>History: -</li>
                            <li>Condition: -</li>
                            <li>Mileage: -</li>
                            <li>Power: -</li>
                            <li>Equipment: -</li>
                        </ul>
                    </p>

                    <p>The NFT will become part of CryptoBartl's Oldtimer Collection: <a href='https://opensea.io/collection/oldtimernftcollection'> https://opensea.io/collection/oldtimernftcollection</a>.</p> 
                    <p>If you would like to get your own personal collection, please let us know and send us the name of your collection - will cost additional 100€. </p>
                    <p>
                        <text style={{fontWeight: "bold"}}>Make sure you send us your crypto wallet address among with the application! </text> <br/>
                        <text>If you do not have a crypto wallet address and would like us to create one for you, this is also do-able, but will cost additional 250€ (side note: it's easier if you create this on your own, by following the steps: https://support.metamask.io/start/getting-started-with-metamask/ , but we can of course also take this over.)
                        After creating the NFT, this will be sent to the mentioned crypto wallet address.</text>
                    </p>
                    <br/>
                    <br/>
                    <p style={{textAlign: "center"}}>
                        <text style={{textAlign: "center"}}>Procedure:</text><br/>
                        <br/>
                        <text style={{textAlign: "center"}}>You apply for the NFT by replying to this email with the completed request form --&gt; We will review your request and, if you qualify, we will create the NFT and send it to you! --&gt; After that, we will generate and send you the receipt --&gt; You make the payment.
                        </text>
                    </p>
                    <br/>
                    <p> 
                        <br/>
                        <text style={{fontStyle: "italic", fontSize: "small"}}>Please note that no additional support will be granted.</text> <br/>
                        <text style={{fontStyle: "italic", fontSize: "small"}}>By starting an order, you agree with the Terms and Conditions and Privacy Policy of CryptoBartl.com (<a href='https://cryptobartl.com/Legals'>https://cryptobartl.com/Legals</a>)
                        </text>
                        <br/>
                    </p>
                    </div>
                )}
                <br/>
                <br/>

                <h3 style={{textAlign: "center"}}>Send an email with the filled-in request over email to emb@cryptobartl.com and we will check your application.</h3>
                <br/>

                <a
                    href={pdfEN}
                    target='_blank'
                    rel='noopener noreferrer'
                    >
                    <button style={{fontWeight: "bolder", borderStyle:"solid", borderColor:"black", cursor:"pointer", backgroundColor:"#FF0000"}}>
                        
                        Click here to Open <br/><br/><br/>
                        <FaFilePdf/><br/><br/><br/>
                        the NFT Request Form <br/>
                        <br/><text style={{color: "black"}}>(english version)</text>
                        <br/>
                        
                    </button>
                </a>___
                <a
                    href={pdfDE}
                    target='_blank'
                    rel='noopener noreferrer'
                    >
                    <button style={{fontWeight: "bolder", borderStyle:"solid", borderColor:"black", cursor:"pointer", backgroundColor:"#FF0000"}}>
                        
                        Click here to Open <br/><br/><br/>
                        <FaFilePdf/><br/><br/><br/>
                        the NFT Request Form <br/>
                        <br/><text style={{color: "black"}}>(german version)</text>
                        <br/>
                        
                    </button>
                </a>

                <br/>
                <br/>
                <br/>
                <br/>

                <button onClick={moveBack} size="large">
                    &larr; Go back
                </button>

                <br/>
                <br/>
                <br/>

                <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
                <p style={{textAlign: "center"}}>Copyright © 2025 CryptoBartl</p>

            </div>
            </div>  
        </main>
    )
}

export default ProjectOldtimerNFT;