export const DatenschutzEN = () => {

    return (
      
      <div> 
          <h1>Privacy Policy</h1>
          <>
            <br/>
            <h3>1. ABOUT THIS PRIVACY NOTICE </h3>
            <p>Privacy and the security of transactions are core elements of cryptocurrencies, blockchain technology and its whole global movement. CryptoBartl really appreciates the trust Users (hereinafter referred to as “User/s” or “you”)  have in us when trading 
            cryptocurrencies and other digital assets on our platform.</p>
            <p>For this reason, privacy and data security have an enormously high priority for the CryptoBartl. It is very important to us that you feel safe during your visit to our website and while using our services as well as over the course of all 
            other business transactions with us. As soon as you make use of products and/or services of CryptoBartl, you entrust us with the processing of your personal data. CryptoBartl wants to give you the best possible experience with our platform to 
            ensure that you enjoy the usage of our products and services now and in the future. That’s why we want to understand the User behaviour on our platform in order to continuously improve it.</p>
            <p>Therefore, in this Privacy Notice, we want to transparently inform you which personal data we collect from you and why and who might receive it. Furthermore, we would like to 
            inform you which precautions we take to protect your personal data, which rights you have in this context and to whom you can turn for data protection concerns.</p>
            <p>This Privacy Notice is drafted in English and German. In case of conflict the German version shall be the binding version.</p>
          </>
          <>
            <br/>
            <h3>2. MEASURES TO PROTECT PERSONAL DATA</h3>
            <p>CryptoBartl informs the users of its website with this data protection declaration about the processing and protection of personal data, 
              which may be collected when navigating or using the services offered through this portal. The use of this 
              Website by the user implies full acceptance of this privacy policy.</p>
          </>
          <>
            <br/>
            <h3>3. INFORMATION, THAT WE COLLECT</h3>
            <p>We collect the information you provide to us, for example when you create or change your account, 
              register to use our website, purchase products or services through our platform, request information, 
              communicate with customer service or generally contact us. Such information may include, but is not limited to:</p>
              <ul>
                <li>Chosen Netwrok (Ethereum or Arbitrum) and payment method</li>
                <li>Crypto address of the buyer, amount bought CCKs and the to be payed Euro amount</li>
                <li>Paypal - Order Id, transaction fee</li>
                <li>Ethereum - transaction Id, transaction hash, transaction fee</li>
                <li>CCK - transaction Id, transaction hash, transaction fee</li>
                <li>Potential error codes during the usage</li>
              </ul>
            <p>Information Collected by Third Party Companies. We may receive information about you or relating to you or your wallet address from service providers and other sources/companies that provide us or you with their products and/or services 
              in connection with our Service or their products and/or services that may be linked from our Service. We may add this to the information we have already collected from or about you through our Service. This information 
              can include, for example:
              Third-party wallet providers and partners who provide us with your blockchain wallet address and certain other information you choose to share with those wallet providers and partners;
              Data analytics providers or vendors who provide us with information about specific blockchain wallet addresses;
              providers who provide communications and identity verification services to us and which you choose to use; And
              other providers who provide us with information necessary to operate our business or enforce our Terms of Use.</p>
          </>

          <>
            <br/>
            <h3>4. THIRD-PARTY WEBSITES</h3>
            <p>Our Service may contain links to third-party websites. If you click on a link to another website or path, you will leave our Service and go to another website, and another company may 
            collect information from you. We have no control over this third-party website or its content and cannot accept any responsibility for it. Please note that this Privacy Policy does not apply to this third party website 
            or their contents or to the collection of your data or information about you after you click on links to such third party websites. We encourage you to read the privacy policies of every website you visit. 
            Any links to third party websites or locations are for your convenience and do not imply our endorsement of those third parties or their products, content or websites.</p>
          </>
          <>
            <br/>
            <h3>5. THIRD-PARTY WALLETS</h3>
            <p>In order to use certain aspects of our Service, you must use a wallet that allows you to conduct transactions on public blockchains. Your interactions with third-party wallet providers 
            are subject to the applicable terms of use and privacy policies of that third party.</p>
          </>
          <>
            <br/>
            <h3>6. DURATION OF DATA STORAGE</h3>
            <p>As the data controller, CryptoBartl undertakes to share the personal data and information collected through this website 
              to store and manage it with the required confidentiality as long as the business relationship continues and the person concerned does not request its deletion, 
              in accordance with the deadlines specified below:</p>
              <ul>
                <li>The data used to process inquiries and applications will be retained for as long as is necessary to respond to them and for a maximum of one year after they have been responded to.</li>
                <li>Data relating to comments on our products will be retained for the duration of the validity and advertising of the products or
                Services to which they relate will be retained unless you express your request for them to be deleted at any time.</li>
                <li>Data used to send commercial communications and create commercial profiles of our products or services will be retained indefinitely, until you request that it be deleted.</li>
                <li>Notwithstanding the foregoing, the data controller may keep the data in a properly blocked form in order to comply with any administrative or judicial obligations during the periods provided for by the applicable regulations.</li>
              </ul>
            <p>CryptoBartl can also anonymize the data after the maximum storage period has expired and summarize it for later statistical and profiling analyses. These analyzes will not enable the re-identification of the data subjects.</p>
          </>
          <>
            <br/>
            <h3>7. PURPOSES OF DATA PROCESSING</h3>
            <p>Processing of personal data refers to the operations, administrative and technical procedures carried out by automated or non-automated means that enable the collection, storage, modification, transmission and other actions with personal data.
            At CryptoBartl we process the personal data that the user provides to us for the provision of the services offered by the platform, including the following:</p>
            <p>Provision of the service: Registered Users' data is processed in order to provide the service, receive payments, issue invoices, upload and send any necessary documents and to verify the subscription to the service. 
            This data may also be affected by the service's internal control functions.</p>
            <p>Profiling: The data provided by the User as well as their actions on the Platform may be used to create a profile that CryptoBartl can use to offer personalized content, products and services.</p>
            <p>Contact with the user and/or support: The user data is used to provide the service and to resolve the reported incidents. Requesting technical assistance may require access to other data of the reporting user depending on the nature of the incident.</p>
            <p>Sending commercial information and promotions: Users' data may be used to incorporate new functions into the system and to send commercial information about the services offered by CryptoBartl, such as discounts and other offers.</p>
            <p>Security checks: The data relating to the use of the CryptoBartl services may be analyzed internally to detect possible fraud and violations of the general terms and conditions of the service, as well as to carry out treatments required by law.</p>
            <p>Statistics: Usage profiles may be analyzed by CryptoBartl to study users' behavior and preferences with the aim of improving the user experience and services.</p>
            <p>Integration with third-party services: CryptoBartl may process the data of its users to enable access to applications integrated into the service, such as automatically and/or directly sending data integrated into the platform to third-party applications.</p>
            <p>In addition to the specific treatments depending on the service you request, CryptoBartl may store the IP address of users' devices, exclusively for technical and security reasons and therefore based on the legitimate interest of ensuring the integrity and security of the service.</p>
          </>
          <>
            <br/>
            <h3>8. LEGAL BASIS FOR DATA PROCESSING</h3>
            <p>The processing of the personal data provided by the user is based on the following legal bases that legitimize it:</p>
            <p>By filling out forms on the Internet, participating in selection procedures, sending emails or any other type of information request to CryptoBartl, the interested party gives their express consent to the processing of their personal data. Equally processed
            CryptoBartl in connection with the provision of the service referred to in the previous section, your personal data for the execution of the contract for the service.</p>
            <p>Likewise, your data may be used based on the legitimate interest of promoting our services and spreading our brand through marketing activities, provided that this interest 
            does not outweigh the fundamental rights and freedoms that require the protection of personal data.</p>
            <p>This consent is understood to be free, specific, informed and unambiguous, since the user is informed after reading this privacy policy and, if he agrees, he will do so by means of a statement or 
            can take a clear affirmative action, such as: B. by clicking a checkbox/button intended for this purpose or by joining groups in instant messaging applications through the client installed on the selected device.</p>
            <p>It is your responsibility to periodically visit our Service and this Privacy Policy to identify any changes.</p>
          </>
          <>
            <br/>
            <h3>9. APPLICABLE DATA PROCESSING PRINCIPLES</h3>
            <p>When processing the personal data of its users, CryptoBartl applies the following principles, which comply with the requirements of the new European Data Protection Regulation:</p>
            <p>Principle of lawfulness, fairness and transparency: The processing of personal data always requires your consent, specifying one or more purposes, of which you will be informed in advance in full transparency.</p>
            <p>Principle of data minimization: Only the data absolutely necessary for the respective purpose is requested. Likewise, in the case of optional additional data that may allow better personalization of the service, express reference is made to their optional nature.</p>
            <p>Principle of limiting the storage period: The data will not be stored longer than is necessary for the purposes of processing.</p>
            <p>Principle of integrity and confidentiality: Your data will be processed in a manner that ensures appropriate security and confidentiality.</p>
          </>
          <>
            <br/>
            <h3>10. MINORS</h3>
            <p>CryptoBartl's products and services are not intended for persons under 18 years of age. Only adults are allowed to use CryptoBartl’s services and register on the platform. 
              Therefore, to the best of our knowledge, we do not collect any personal data from minors. So if you are under 18 years old, please do not use our platform or submit any personal information to us.</p>
            <p>We intentionally do not collect information from visitors who are under 18 years old. Our Terms of Service require all users to be at least 18 years old. Minors who are at least 13 years old
               (or at least 16 years for EEA/UK individuals) but under 18 years of age may use a parent or guardian's account, but only under the supervision of the account holder. 
               If a child under the age of 13 (or under 16 for those in the EEA/UK) provides us with identifiable information and we learn that the identifiable information is the information of a child under the age of 13 
               (or under 16 for those in the EEA/UK), we will attempt to delete the information as quickly as possible. If you believe that we may receive identifiable information from a child under 13 years of age 
               (or under 16 for those in the EEA/UK), please contact us at cryptobartl-emb@outlook.com.</p>
          </>
          <>
            <br/>
            <h3>11. RIGHTS OF AFFECTED PARTIES</h3>
            <p>The user may at any time, under the conditions established by current legislation, exercise the right of access, rectification or deletion of the data, request the restriction of the processing of his personal data, object to it, the portability of his data 
            request and revoke the consent given, rights recognized in the above-mentioned regulation (EU). The user can exercise these rights by sending CryptoBartl an email to the following address cryptobartl-emb@outlook.com.</p>
            <p>The data subjects have the right to access their personal data, to obtain confirmation as to whether or not personal data concerning them are being processed, as well as to have inaccurate data corrected or, if necessary 
            to request its deletion if, among other things, the data is no longer necessary for the purposes for which it was collected. Under certain circumstances, data subjects can request the restriction of the processing of their data; 
            In this case, the data will only be retained to assert or defend against claims that may arise.</p>
            <p>Affected persons can also object to the processing of their data. In this case, CryptoBartl will stop processing the data unless there are compelling legitimate reasons or it involves the assertion or defense of any claims.</p>
            <p>As a data subject, you have the right to receive the personal data concerning you in a structured, commonly used and machine-readable format and to transmit them to another data controller if you wish:
            The processing takes place on the basis of consent.
            The data was provided by the interested party.
            The processing is carried out using automated means.
            When exercising your right to data portability, you have the right to have personal data transmitted directly from one controller to another, where technically feasible.</p>
            <p>For treatments that are based on the user's consent, you can revoke this consent at any time. However, the withdrawal of your consent does not affect the lawfulness of the processing based on your consent before its withdrawal 
            has taken place, nor the lawfulness of the processing that we continue to carry out on another legal basis.</p>
            <p>To effectively exercise these rights, the user must prove his identity by submitting his first and last name, a photocopy of his identity card or an equivalent document proving his identity, an application in which the request 
            is specified, an address for deliveries as well as the date and signature of the applicant.</p>
          </>
          <>
            <br/>
            <h3>12. RIGHT TO BE FORGOTTEN</h3>
            <p>By using this website, you agree that you have the right to request the deletion or forgetting of your personal data in accordance with applicable data protection laws. If you would like to exercise this right, 
            please contact us using the contact information provided on our website.</p>
            <p>We will consider all requests to delete or forget data in accordance with applicable laws and regulations. In some cases we may be required to retain certain data for legal or regulatory reasons. 
            If we are unable to comply with your request to delete or forget data, we will inform you of the reasons why this is not possible.</p>
            <p>Please note that the right to be forgotten is not an absolute right but may be restricted in certain circumstances. For example, it may be necessary for us to process certain data for legal or regulatory reasons or for 
            must be kept for the purposes of asserting or defending legal claims. In addition, it may not be possible to delete or forget data if this is necessary for the performance of a contract or service you have requested.</p>
            <p>We will use reasonable endeavors to ensure that all personal data held is accurate and up to date. You have the right to request that inaccurate information be corrected or updated.</p>
            <p>We may update these Terms from time to time. Any changes will be posted on our website and your continued use of our website will constitute acceptance of any updated terms.</p>
            <p>By using our website, you agree to the terms and conditions set out in this section on the right to be forgotten.</p>
          </>
          <>
            <br/>
            <h3>13. DATA BREACHES</h3>
            <p>We take the security of your personal information very seriously and have implemented measures to protect against unauthorized access, alteration or disclosure. In the event of a data breach, we will take appropriate steps to: 
            to notify affected users and authorities as required by law.</p>
            <p>If we learn of a data breach that compromises the security of your personal information, we will take steps to investigate the breach and mitigate the damage. This can be done by notifying the affected users, 
            This includes updating our security measures and cooperating with law enforcement authorities.</p>
            <p>We are not responsible for any damages resulting from any data breach beyond our control, including, but not limited to, attacks by malicious third parties or natural disasters. However, we will 
            take all reasonable measures to prevent and mitigate possible violations.</p>
            <p>By using our Services, you acknowledge that no method of transmission over the Internet or electronic storage is 100% secure, and you agree to assume all risks associated with such use 
            to ensure the security of your personal information.</p>
          </>
          <>
            <br/>
            <h3>14. DATA TRANSFER OUTSIDE THE EU</h3>
            <p>In the course of providing our services, we may transfer your personal data to our affiliates or third party service providers outside the European Union (EU) or the European Economic Area (EEA), where data protection laws apply 
            may not be as comprehensive as within the EU or EEA.</p>
            <p>By using our Services, you consent to the transfer of your personal data outside the EU or EEA as described in this clause. If you have any questions or concerns about this data transfer, 
            please contact cryptobartl-emb@outlook.com. We will take all reasonable steps to address your concerns and ensure that your personal information is adequately protected.</p>
          </>
          <>
            <br/>
            <h3>15. COPYRIGHT, PUBLICATION</h3>
            <p>The copyright must be ensured by the customer and is transferred to the creator of the NFT.  
              By posting or submitting content, you represent and warrant that:
<li>You have all necessary rights, licenses, consents, permissions, power, and authority to grant OpenSea the license described above.</li>
<li>Your content does not violate any intellectual property rights, publicity rights, or other third-party rights, and does not contain material that violates any laws.</li>
<li>You are responsible for the content and metadata associated with your NFTs and digital items, including ensuring that they do not contain infringing material unless you have obtained the required permissions or are otherwise legally entitled to post the material.</li>
            </p>
            <p>
            CryptoBartl grants you a limited, nonexclusive, nontransferable, nonsublicensable, and personal license to access and use the Platform, subject to your compliance with these Terms. This includes:
            <li>Accessing and displaying any software, content, or materials made available by OpenSea solely for your personal, non-commercial use as permitted under these Terms.</li>
            <li>Downloading a copy of the OpenSea mobile application to your device solely to enable your use of the Platform.</li>
            <li>This license is strictly limited to your lawful use of the Platform. OpenSea does not grant any ownership rights through this license, and your use of content linked to or associated with NFTs, NFT collections, or other digital items is subject to any applicable rights set forth by the seller or creator of the NFT or such other digital item.</li>
            </p>
            <p>The customer agrees that the information, files and data obtained can be stored on the blockchain forever,
without the possibility of removing them. CryptoBartl reserves the right to reduce the quality of the received files
if it considers this necessary.
            </p>
            <p>If the NFT is created using OpenSea (https://opensea.io/), all associated privacy policies and data protection declarations are accepted. This also applies to wallet creation using MetaMask.
            </p>
            <p>
            The aim is for the order to be approved or rejected by CryptoBartl within forty-eight hours (weekdays only). CryptoBartl is entitled to reject orders without giving any reason. 
            </p>

          </>
      </div>
  
    )
  };

  export const DatenschutzDE = () => {

    return (
      
      <div> 
          <h1>Datenschutzerklärung</h1>
          <>
            <br/>
            <h3>1. ÜBER DIESE DATENSCHUTZERKLÄRUNG </h3>
            <p>Datenschutz und die Sicherheit von Transaktionen sind Kernelemente von Kryptowährungen, der Blockchain-Technologie und ihrer gesamten globalen Bewegung. CryptoBartl schätzt das Vertrauen, dass du als Nutzer (im Folgenden als „du“ oder „Kunde” bezeichnet) 
            beim Handel mit Kryptowährungen und anderen digitalen Assets auf unserer Plattform entgegenbringst.</p>
            <p>Aus diesem Grund haben Datenschutz und Datensicherheit für die CryptoBartl eine enorm hohe Priorität. Es ist uns sehr wichtig, dass du dich beim Besuch unserer Website und der Nutzung unserer Dienste sowie bei 
            allen anderen Geschäftsvorgängen mit uns sicher fühlst. Sobald du die Produkte und Dienstleistungen von CryptoBartl nutzt, vertraust du uns deine personenbezogenen Daten an. Wir möchten dir die bestmögliche 
            Erfahrung mit unserer Plattform bieten, damit du die Nutzung unserer Produkte und Dienstleistungen jetzt und in Zukunft genießen kannst. Deshalb wollen wir auch das Nutzerverhalten auf unserer Plattform verstehen, um diese kontinuierlich zu verbessern. </p>
            <p>Wir möchten dich daher mit dieser Datenschutzerklärung transparent und detailliert darüber informieren, welche personenbezogenen Daten wir von dir erheben, wie wir sie verarbeiten und an wen wir sie übermitteln. 
            Darüber hinaus möchten wir dich darüber informieren, welche Vorkehrungen wir zum Schutz deiner personenbezogenen Daten treffen, welche Rechte du in diesem Zusammenhang hast und an wen du dich für datenschutzrechtliche Fragen wenden kannst.</p>
            <p>Diese Datenschutzerklärung ist in deutscher und englischer Sprache verfasst. Im Falle von Unstimmigkeiten ist die deutsche Fassung vorrangig.</p>
          </>
          <>
            <br/>
            <h3>2. MASSNAHMEN ZUM SCHUTZ PERSONENBEZOGENER DATEN</h3>
            <p>CryptoBartl informiert die Nutzer seiner Website mit dieser Datenschutzerklärung über die Verarbeitung und den Schutz personenbezogener Daten, 
              die bei der Navigation oder der Inanspruchnahme der über dieses Portal angebotenen Dienstleistungen erhoben werden können. Die Nutzung dieser 
              Website durch den Nutzer impliziert die vollständige Akzeptanz dieser Datenschutzrichtlinie.</p>
          </>
          <>
            <br/>
            <h3>3. INFORMATIONEN, DIE WIR SAMMELN</h3>
            <p>Wir erfassen die Informationen, die Sie uns zur Verfügung stellen, wenn Sie beispielsweise Ihr Konto erstellen oder ändern, 
              sich für die Nutzung unserer Website registrieren, Produkte oder Dienstleistungen über unsere Plattform erwerben, Informationen anfordern, 
              mit dem Kundendienst kommunizieren oder allgemein mit uns in Kontakt treten. Solche Informationen können unter anderem Folgendes umfassen:</p>
              <ul>
                <li>Ausgewähltes Netzwerk (Ethereum oder Arbitrum) und Bezahlmethode</li>
                <li>Crypto Addresse des Käufers, Menge erworbenerer CCKs und der zahlende Betrag in Euro</li>
                <li>Paypal - OrderID, Transaktionsfee</li>
                <li>Ethereum - Transaktionsbetrag, Transaktionshash, Transaktionsfee</li>
                <li>CCK - Transaktionsbetrag, Transaktionshash, Transaktionsfee</li>
                <li>Potentielle Fehlercodes während der Nutzung</li>
              </ul>
            <p>Von Drittunternehmen gesammelte Informationen. Wir erhalten möglicherweise Informationen über Sie oder in Bezug auf Sie oder Ihre Wallet-Adresse von Dienstleistern und anderen Quellen/Unternehmen, die uns oder Ihnen ihre Produkte und/oder Dienstleistungen zur 
              Nutzung in Verbindung mit unserem Service anbieten oder deren Produkte und/oder Dienste können von unserem Dienst aus verlinkt sein. Wir können dies zu den Daten hinzufügen, die wir bereits über unseren Service von Ihnen oder über Sie erfasst haben. Zu diesen Informationen 
              können beispielsweise gehören:
              Drittanbieter von Wallets und Partnern, die uns Ihre Blockchain-Wallet-Adresse und bestimmte andere Informationen zur Verfügung stellen, die Sie diesen Wallet-Anbietern und Partnern mitteilen möchten;
              Datenanalyseanbieter oder -verkäufer, die uns Informationen zu bestimmten Blockchain-Wallet-Adressen bereitstellen;
              Anbieter, die uns Kommunikations- und Identitätsprüfungsdienste anbieten und die Sie nutzen möchten; Und
              andere Anbieter, die uns Informationen zur Verfügung stellen, die für den Betrieb unseres Unternehmens oder die Durchsetzung unserer Nutzungsbedingungen erforderlich sind.</p>
          </>

          <>
            <br/>
            <h3>4. THIRD-PARTY WEBSITES</h3>
            <p>Unser Service kann Links zu Websites Dritter enthalten. Wenn Sie auf einen Link zu einer anderen Website oder einem anderen Pfad klicken, verlassen Sie unseren Dienst und gelangen zu einer anderen Website, und ein anderes Unternehmen kann möglicherweise 
            Informationen von Ihnen sammeln. Wir haben keine Kontrolle über diese Third-Party Website oder deren Inhalte und können keine Verantwortung dafür übernehmen. Bitte beachten Sie, dass diese Datenschutzrichtlinie nicht für diese Third-Party Website 
            oder deren Inhalte oder für die Erfassung Ihrer Daten oder Informationen über Sie gilt, nachdem Sie auf Links zu solchen Websites Dritter geklickt haben. Wir empfehlen Ihnen, die Datenschutzrichtlinien jeder Website, die Sie besuchen, zu lesen. 
            Alle Links zu Websites oder Standorten Dritter dienen Ihrer Bequemlichkeit und bedeuten nicht, dass wir diese Dritten oder deren Produkte, Inhalte oder Websites befürworten.</p>
          </>
          <>
            <br/>
            <h3>5. THIRD-PARTY WALLETS</h3>
            <p>Um bestimmte Aspekte unseres Dienstes nutzen zu können, müssen Sie ein Wallet verwenden, das Ihnen die Durchführung von Transaktionen auf öffentlichen Blockchains ermöglicht. Ihre Interaktionen mit Drittanbieter-Wallet-Anbietern 
            unterliegen den geltenden Nutzungsbedingungen und Datenschutzrichtlinien dieses Drittanbieters.</p>
          </>
          <>
            <br/>
            <h3>6. DAUER DER DATENSPEICHERUNG</h3>
            <p>Als Verantwortlicher für die Verarbeitung verpflichtet sich CryptoBartl, die über diese Website gesammelten persönlichen Daten und Informationen mit 
              der gebotenen Vertraulichkeit zu speichern und zu verwalten, solange die Geschäftsbeziehung andauert und der Betroffene nicht ihre Löschung verlangt, 
              und zwar gemäß den unten angegebenen Fristen:</p>
              <ul>
                <li>Die Daten zur Bearbeitung von Anfragen und Anträgen werden so lange aufbewahrt, wie es für ihre Beantwortung erforderlich ist, und nach ihrer Beantwortung höchstens ein Jahr lang.</li>
                <li>Daten, die sich auf Kommentare zu unseren Produkten beziehen, werden für die Dauer der Gültigkeit und der Werbung für die Produkte oder
                Dienstleistungen, auf die sie sich beziehen, aufbewahrt, es sei denn, Sie äußern den Wunsch, dass sie jederzeit gelöscht werden.</li>
                <li>Daten für den Versand kommerzieller Mitteilungen und die Erstellung kommerzieller Profile unserer Produkte oder Dienstleistungen werden auf unbestimmte Zeit aufbewahrt, bis Sie gegebenenfalls den Wunsch äußern, dass sie gelöscht werden sollen.</li>
                <li>Ungeachtet dessen kann der für die Verarbeitung Verantwortliche die Daten in ordnungsgemäß gesperrter Form aufbewahren, um eventuellen administrativen oder gerichtlichen Verpflichtungen während der in den geltenden Vorschriften vorgesehenen Zeiträume nachzukommen.</li>
              </ul>
            <p>Ebenso kann CryptoBartl nach Ablauf der maximalen Speicherdauer die Daten anonymisieren und für spätere statistische und profilbildende Analysen zusammenfassen. Diese Analysen werden keine Re-Identifizierung der betroffenen Personen ermöglichen.</p>
          </>
          <>
            <br/>
            <h3>7. ZWECKE DER DATENVERARBEITUNG</h3>
            <p>Als Verarbeitung personenbezogener Daten gelten die automatisiert oder nicht automatisiert durchgeführten Vorgänge, Verwaltungs- und technischen Verfahren, die das Erheben, Speichern, Ändern, Übermitteln und andere Handlungen mit personenbezogenen Daten ermöglichen.
            Bei CryptoBartl verarbeiten wir die personenbezogenen Daten, die uns der Nutzer für die Erbringung der von der Plattform angebotenen Dienstleistungen zur Verfügung stellt, u.a. die folgenden:</p>
            <p>Erbringung der Dienstleistung: Die Daten der registrierten Nutzer werden verarbeitet, um die Dienstleistung zu erbringen, Zahlungen zu erhalten, Rechnungen auszustellen, eventuell erforderliche Dokumente zu laden und zu versenden und um das Abonnement des Dienstes zu überprüfen. 
            Ebenso können diese Daten von internen Kontrollfunktionen des Dienstes betroffen sein.</p>
            <p>Profilierung: Die vom Nutzer zur Verfügung gestellten Daten sowie seine Handlungen auf der Plattform können verwendet werden, um ein Profil zu erstellen, das CryptoBartl nutzen kann, um personalisierte Inhalte, Produkte und Dienstleistungen anzubieten.</p>
            <p>Kontakt mit dem Nutzer und/oder dem Support: Die Nutzerdaten werden zur Erbringung des Dienstes und zur Lösung der gemeldeten Vorfälle verwendet. Die Anforderung von technischer Unterstützung kann je nach Art des Vorfalls den Zugriff auf andere Daten des meldenden Nutzers erfordern.</p>
            <p>Zusendung von kommerziellen Informationen und Werbeaktionen: Die Daten der Nutzer können verwendet werden, um neue Funktionen in das System einzubauen und kommerzielle Informationen über die von CryptoBartl angebotenen Dienstleistungen, wie z.B. Rabatte und andere Angebote, zu versenden.</p>
            <p>Sicherheitsprüfungen: Die Daten über die Nutzung der CryptoBartl-Dienste können intern analysiert werden, um möglichen Betrug und Verstöße gegen die Allgemeinen Geschäftsbedingungen des Dienstes aufzudecken, sowie um gesetzlich vorgeschriebene Behandlungen durchzuführen.
            Statistik: Nutzungsprofile können von CryptoBartl analysiert werden, um das Verhalten und die Vorlieben der Nutzer zu untersuchen, mit dem Ziel, die Nutzererfahrung und die Dienstleistungen zu verbessern.</p>
            <p>Integration mit Dienstleistungen Dritter: CryptoBartl kann die Daten seiner Nutzer verarbeiten, um den Zugang zu Anwendungen zu ermöglichen, die in die Dienstleistung integriert sind, wie z.B. das automatische und/oder direkte Senden von in die Plattform integrierten Daten an Anwendungen Dritter.</p>
            <p>Zusätzlich zu den spezifischen Behandlungen je nach der von Ihnen angeforderten Dienstleistung kann CryptoBartl die IP-Adresse der Geräte der Nutzer speichern, und zwar ausschließlich aus technischen und sicherheitstechnischen Gründen und somit auf der Grundlage des berechtigten Interesses, die Integrität und Sicherheit der Dienstleistung zu gewährleisten.</p>
          </>
          <>
            <br/>
            <h3>8. RECHTSGRUNDLAGE FÜR DIE DATENVERARBEITUNG</h3>
            <p>Die Verarbeitung der vom Nutzer zur Verfügung gestellten personenbezogenen Daten stützt sich auf die folgenden Rechtsgrundlagen, die sie legitimieren:</p>
            <p>Durch das Ausfüllen von Formularen im Internet, die Teilnahme an Auswahlverfahren, das Versenden von E-Mails oder jede andere Art von Informationsanfragen an CryptoBartl gibt der Interessent seine ausdrückliche Zustimmung zur Verarbeitung seiner persönlichen Daten. Ebenso verarbeitet
            CryptoBartl im Zusammenhang mit der Erbringung der im vorherigen Abschnitt genannten Dienstleistung Ihre personenbezogenen Daten für die Ausführung des Vertrags für die Dienstleistung.</p>
            <p>Ebenso können Ihre Daten auf der Grundlage des berechtigten Interesses an der Förderung unserer Dienstleistungen und der Verbreitung unserer Marke durch Marketingmaßnahmen verwendet werden, vorausgesetzt, dass dieses Interesse nicht die Grundrechte und -freiheiten überwiegt, die den Schutz personenbezogener Daten erfordern.</p>
            <p>Diese Zustimmung wird als frei, spezifisch, informiert und eindeutig verstanden, da der Nutzer nach dem Lesen dieser Datenschutzrichtlinie informiert ist und sie, wenn er zustimmt, durch eine Erklärung oder eine eindeutige bestätigende Handlung annehmen kann, wie z. B. durch Anklicken eines zu diesem Zweck vorgesehenen Kontrollkästchens oder durch Beitritt zu Gruppen in Instant-Messaging-Anwendungen über den auf dem gewählten Gerät installierten Client.</p>
            <p>Es liegt in Ihrer Verantwortung, unseren Service und diese Datenschutzrichtlinie regelmäßig zu besuchen, um etwaige Änderungen festzustellen.</p>
          </>
          <>
            <br/>
            <h3>9. ANWENDBARE GRUNDSÄTZE FÜR DIE DATENVERARBEITUNG</h3>
            <p>Bei der Verarbeitung der persönlichen Daten seiner Nutzer wendet CryptoBartl die folgenden Grundsätze an, die den Anforderungen der neuen europäischen Datenschutzverordnung entsprechen:</p>
            <p>Grundsatz der Rechtmäßigkeit, Fairness und Transparenz: Für die Verarbeitung personenbezogener Daten ist immer Ihre Zustimmung erforderlich, wobei ein oder mehrere Zwecke angegeben werden müssen, über die Sie vorher in absoluter Transparenz informiert werden.</p>
            <p>Grundsatz der Datenminimierung: Es werden nur die für den jeweiligen Zweck unbedingt erforderlichen Daten angefordert. Ebenso wird im Falle fakultativer zusätzlicher Daten, die eine bessere Personalisierung des Dienstes ermöglichen können, ausdrücklich auf deren fakultativen Charakter hingewiesen.</p>
            <p>Grundsatz der Begrenzung der Speicherdauer: Die Daten werden nicht länger gespeichert, als es für die Zwecke der Verarbeitung erforderlich ist.</p>
            <p>Grundsatz der Integrität und Vertraulichkeit: Ihre Daten werden in einer Weise verarbeitet, die eine angemessene Sicherheit und Vertraulichkeit gewährleistet.</p>
          </>
          <>
            <br/>
            <h3>10. MINDERJÄHRIGE</h3>
            <p>Die Produkte und Dienstleistungen von CryptoBartl sind nicht für Personen unter 18 Jahren bestimmt. Nur volljährige Personen dürfen die Dienste von CryptoBartl nutzen und sich auf der Plattform registrieren. 
              Daher sammeln wir nach unserem Wissensstand auch keine personenbezogenen Daten von Minderjährigen. Wenn du also unter 18 Jahre alt bist, nutze bitte nicht unsere Plattform und übermittle keine personenbezogenen Daten an uns.</p>
            <p>Wir sammeln absichtlich keine Informationen von Besuchern, die unter 18 Jahre alt sind. Gemäß unseren Nutzungsbedingungen müssen alle Benutzer mindestens 18 Jahre alt sein. Minderjährige, die mindestens 13 Jahre alt sind
               (oder mindestens 16 Jahre für Einzelpersonen im EWR/UK), aber unter 18 Jahre alt sind, dürfen das Konto eines Elternteils oder Erziehungsberechtigten nutzen, jedoch nur unter Aufsicht des Kontoinhabers. 
               Wenn ein Kind unter 13 Jahren (oder unter 16 Jahren für Personen im EWR/Vereinigten Königreich) uns identifizierbare Informationen übermittelt und wir erfahren, dass es sich bei den identifizierbaren Informationen um die Informationen eines Kindes unter 13 Jahren 
               (oder unter 16 Jahren für Personen im EWR/Vereinigten Königreich) handelt, Wir werden versuchen, die Informationen so schnell wie möglich zu löschen. Wenn Sie glauben, dass wir möglicherweise identifizierbare Informationen von einem Kind unter 13 Jahren 
               (oder unter 16 Jahren für Personen im EWR/Großbritannien) haben, kontaktieren Sie uns bitte über cryptobartl-emb@outlook.com.</p>
          </>
          <>
            <br/>
            <h3>11. RECHTE DER BETROFFENEN PARTEIEN</h3>
            <p>Der Nutzer kann jederzeit unter den in der geltenden Gesetzgebung festgelegten Bedingungen das Recht auf Zugang, Berichtigung oder Löschung der Daten ausüben, die Einschränkung der Verarbeitung seiner personenbezogenen Daten verlangen, Widerspruch dagegen einlegen, die Übertragbarkeit seiner Daten 
            verlangen sowie die erteilte Zustimmung widerrufen, Rechte, die in der oben genannten Verordnung (EU) anerkannt werden. Der Nutzer kann diese Rechte ausüben, indem er sich an CryptoBartl eine E-Mail an die folgende Adresse schickt cryptobartl-emb@outlook.com.</p>
            <p>Die Betroffenen haben das Recht, ihre personenbezogenen Daten einzusehen, eine Bestätigung darüber zu erhalten, ob sie betreffende personenbezogene Daten verarbeitet werden oder nicht, sowie die Berichtigung unrichtiger Daten oder gegebenenfalls 
            deren Löschung zu verlangen, wenn die Daten unter anderem für die Zwecke, für die sie erhoben wurden, nicht mehr erforderlich sind. Unter bestimmten Umständen können Betroffene die Einschränkung der Verarbeitung ihrer Daten beantragen; 
            in diesem Fall werden die Daten nur zur Geltendmachung oder Abwehr von Ansprüchen aufbewahrt, die sich ergeben können.</p>
            <p>Betroffene Personen können auch der Verarbeitung ihrer Daten widersprechen. In diesem Fall wird CryptoBartl die Verarbeitung der Daten einstellen, es sei denn, es liegen zwingende berechtigte Gründe vor oder es geht um die Geltendmachung oder Abwehr etwaiger Ansprüche.</p>
            <p>Als betroffene Person haben Sie das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und sie an einen anderen für die Verarbeitung Verantwortlichen zu übermitteln, wenn Sie dies wünschen:
            Die Verarbeitung erfolgt auf der Grundlage einer Einwilligung.
            Die Daten wurden von der interessierten Partei zur Verfügung gestellt.
            Die Verarbeitung erfolgt mit automatisierten Mitteln.
            Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit haben Sie das Recht, personenbezogene Daten direkt von einem für die Verarbeitung Verantwortlichen an einen anderen zu übermitteln, sofern dies technisch machbar ist.</p>
            <p>Bei Behandlungen, die auf der Einwilligung des Nutzers beruhen, können Sie diese Einwilligung jederzeit widerrufen. Der Widerruf Ihrer Einwilligung berührt jedoch nicht die Rechtmäßigkeit der Verarbeitung, die aufgrund Ihrer Einwilligung vor dem Widerruf 
            erfolgt ist, und auch nicht die Rechtmäßigkeit der Verarbeitung, die wir weiterhin aufgrund einer anderen Rechtsgrundlage vornehmen.</p>
            <p>Zur wirksamen Ausübung dieser Rechte muss der Nutzer seine Identität nachweisen, indem er seinen Vor- und Nachnamen, eine Fotokopie seines Personalausweises oder eines gleichwertigen Ausweises, der seine Identität belegt, einen Antrag, in dem der Antrag 
            spezifiziert wird, eine Adresse für Zustellungen sowie das Datum und die Unterschrift des Antragstellers angibt.</p>
          </>
          <>
            <br/>
            <h3>12. RECHT AUF VERGESSENWERDEN</h3>
            <p>Durch die Nutzung dieser Website erklären Sie sich damit einverstanden, dass Sie das Recht haben, die Löschung oder das Vergessen Ihrer personenbezogenen Daten in Übereinstimmung mit den geltenden Datenschutzgesetzen zu verlangen. Wenn Sie von diesem Recht Gebrauch machen möchten, 
            kontaktieren Sie uns bitte über die auf unserer Website angegebenen Kontaktinformationen.</p>
            <p>Wir werden alle Anträge auf Löschung oder Vergessen von Daten im Einklang mit den geltenden Gesetzen und Vorschriften prüfen. In einigen Fällen kann es sein, dass wir aus rechtlichen oder regulatorischen Gründen verpflichtet sind, bestimmte Daten aufzubewahren. 
            Wenn wir Ihrem Antrag auf Löschung oder Vergessen von Daten nicht nachkommen können, werden wir Sie über die Gründe dafür informieren, dass dies nicht möglich ist.</p>
            <p>Bitte beachten Sie, dass das Recht auf Vergessenwerden kein absolutes Recht ist, sondern unter bestimmten Umständen eingeschränkt werden kann. So kann es beispielsweise erforderlich sein, dass wir bestimmte Daten aus rechtlichen oder behördlichen Gründen oder zur 
            Geltendmachung oder Verteidigung von Rechtsansprüchen aufbewahren müssen. Darüber hinaus kann es sein, dass die Löschung oder das Vergessen von Daten nicht möglich ist, wenn dies für die Erfüllung eines Vertrags oder einer Dienstleistung, die Sie angefordert haben, erforderlich ist.</p>
            <p>Wir werden uns in angemessener Weise bemühen, sicherzustellen, dass alle aufbewahrten personenbezogenen Daten richtig und aktuell sind. Sie haben das Recht, zu verlangen, dass unrichtige Daten korrigiert oder aktualisiert werden.</p>
            <p>Wir können diese Bedingungen von Zeit zu Zeit aktualisieren. Alle Änderungen werden auf unserer Website veröffentlicht und Ihre fortgesetzte Nutzung unserer Website gilt als Zustimmung zu allen aktualisierten Bedingungen.</p>
            <p>Durch die Nutzung unserer Website erklären Sie sich mit den Bedingungen einverstanden, die in diesem Abschnitt über das Recht auf Vergessenwerden aufgeführt sind.</p>
          </>
          <>
            <br/>
            <h3>13. DATENVERSTÖSSE</h3>
            <p>Wir nehmen die Sicherheit Ihrer persönlichen Daten sehr ernst und haben Maßnahmen zum Schutz vor unbefugtem Zugriff, Veränderung oder Offenlegung getroffen. Im Falle eines Datenschutzverstoßes werden wir geeignete Schritte unternehmen, 
            um die betroffenen Nutzer und Behörden wie gesetzlich vorgeschrieben zu benachrichtigen.</p>
            <p>Wenn wir von einem Datenschutzverstoß erfahren, der die Sicherheit Ihrer persönlichen Daten gefährdet, werden wir Maßnahmen ergreifen, um den Verstoß zu untersuchen und den Schaden zu mindern. Dazu kann die Benachrichtigung der betroffenen Nutzer, 
            die Aktualisierung unserer Sicherheitsmaßnahmen und die Zusammenarbeit mit den Strafverfolgungsbehörden gehören.</p>
            <p>Wir sind nicht verantwortlich für Schäden, die aus einer Datenverletzung resultieren, die außerhalb unserer Kontrolle liegt, einschließlich, aber nicht beschränkt auf Angriffe durch böswillige Dritte oder Naturkatastrophen. Wir werden jedoch 
            alle angemessenen Maßnahmen ergreifen, um mögliche Verstöße zu verhindern und abzumildern.</p>
            <p>Durch die Nutzung unserer Dienste erkennen Sie an, dass keine Methode der Übertragung über das Internet oder der elektronischen Speicherung zu 100 % sicher ist, und Sie erklären sich damit einverstanden, alle Risiken im Zusammenhang mit der 
            Sicherheit Ihrer persönlichen Daten zu übernehmen.</p>
          </>
          <>
            <br/>
            <h3>14. DATENÜBERMITTLUNG AUSSERHALB DER EU</h3>
            <p>Im Zuge der Erbringung unserer Dienstleistungen können wir Ihre personenbezogenen Daten an unsere verbundenen Unternehmen oder Drittanbieter außerhalb der Europäischen Union (EU) oder des Europäischen Wirtschaftsraums (EWR) übermitteln, wo die Datenschutzgesetze 
            möglicherweise nicht so umfassend sind wie innerhalb der EU oder des EWR.</p>
            <p>Indem Sie unsere Dienste nutzen, stimmen Sie der in dieser Klausel beschriebenen Übermittlung Ihrer personenbezogenen Daten außerhalb der EU oder des EWR zu. Sollten Sie Fragen oder Bedenken zu dieser Datenübermittlung haben, 
            wenden Sie sich bitte an cryptobartl-emb@outlook.com. Wir werden alle angemessenen Schritte unternehmen, um Ihre Bedenken auszuräumen und sicherzustellen, dass Ihre personenbezogenen Daten angemessen geschützt sind.</p>
          </>
          <>
            <br/>
            <h3>15. URHEBERRECHT, VERÖFFENTLICHUNG</h3>
            <p>Das Urheberrecht muss vom Kunde sichergestellt werden und wird an dem Ersteller des NFTs übertragen.  
              Indem Sie Inhalte veröffentlichen oder übermitteln, erklären und garantieren Sie Folgendes:
              <li>Sie verfügen über alle erforderlichen Rechte, Lizenzen, Zustimmungen, Genehmigungen, Befugnisse und Vollmachten, 
                um CryptoBartl die oben beschriebene Lizenz zu erteilen.</li>
              <li>Ihre Inhalte verletzen keine geistigen Eigentumsrechte, Veröffentlichungsrechte oder andere Rechte Dritter und enthalten kein Material, das gegen Gesetze verstößt.</li>
              <li>Sie sind für die Inhalte und Metadaten Ihrer NFTs/Antrags und digitalen Artikel verantwortlich und müssen sicherstellen, dass diese kein rechtsverletzendes Material enthalten, es sei denn, Sie verfügen über die erforderlichen Genehmigungen oder sind anderweitig gesetzlich berechtigt, das Material zu veröffentlichen.</li>
            </p>
            <p>
              CryptoBartl gewährt Ihnen eine begrenzte, nicht exklusive, nicht übertragbare, nicht unterlizenzierbare und persönliche Lizenz für den Zugriff und die Nutzung der Plattform, vorbehaltlich Ihrer Einhaltung dieser Bedingungen. Dies umfasst:
              <li>Zugriff auf und Anzeige von Software, Inhalten oder Materialien, die von OpenSea ausschließlich für Ihre persönliche, nicht kommerzielle Nutzung bereitgestellt werden, wie in diesen Bedingungen gestattet.</li>
              <li>Herunterladen einer Kopie der mobilen OpenSea-Anwendung auf Ihr Gerät, ausschließlich um Ihnen die Nutzung der Plattform zu ermöglichen.</li>
              <li>Diese Lizenz ist streng auf Ihre rechtmäßige Nutzung der Plattform beschränkt. OpenSea gewährt durch diese Lizenz keine Eigentumsrechte, und Ihre Nutzung von Inhalten, die mit NFTs, NFT-Sammlungen oder anderen digitalen Artikeln verknüpft oder damit verbunden sind, unterliegt allen geltenden Rechten, die vom Verkäufer oder Ersteller des NFT oder eines solchen anderen digitalen Artikels festgelegt wurden.</li>
            </p>
            <p>Der Kunde ist damit einverstanden, dass die ermittelte Informationen, Dateien und Daten auf dem Blockchain für immer gespeichert werden können,
              ohne die Möglichkeit zu haben diese zu entfernen. CryptoBartl behält das Recht, die Qualität der empfangenen Dateien zu
              reduzieren, falls dies für notwendig gehalten wird.
            </p>
            <p>Falls das NFT mittels OpenSea (https://opensea.io/) erstellt werden, sind alle damit verbundenen PrivacyPolices und Datenschutzerlärungen
              zu akzeptieren. Dies gilt auch für eine Wallet-Erstellung mittels MetaMask.
            </p>
            <p>
              Ziel ist es, dass der Auftrag innerhalb von achtundvierzig Stunden (nur Wochentagen) von CryptoBartl genehmigt oder 
              abgelehnt wird. CryptoBartl ist berechtigt, Aufträge ohne Angabe von Gründen abzulehnen. 
            </p>

          </>
      </div>
    )
  };