import React, {useState} from 'react';
import { NavComponent } from '../navigationComponents/NavComponent';

function ContactUs()
{

    return (
        <main>
            <div id="divHomepage" className='homepageDesign'>
            
            <NavComponent/>
            
                
                <div>
                    <p></p>
                    <p></p>
                    <h2 style={{textAlign:"center"}}> Get connected with us on social networks:</h2>
                    <br/>
                    <br/>
                    <a href="https://twitter.com/CryptoBartl/">CryptoBartl - on X (Twitter)</a>
                    <p></p>
                    <a href="https://t.me/cryptobartl">CryptoBartl - on Telegram</a>
                    <br/>
                    <br/>
                    <br/>
                    <h3 style={{textAlign:"center"}}>Email : emb@cryptobartl.com</h3>
                    <h3 style={{textAlign:"center"}}>Email : cryptobartl-emb@outlook.com</h3>
                    <p></p>
                    <br/>
                    <br/>
                    <br/>
                    <h3 style={{textAlign:"center"}}>Legals:</h3>
                    <a href="/Legals">|Legal Notice / Impressum| </a>
                    <a href="/Legals"> |Terms and Conditions / AGBs| </a>
                    <a href="/Legals"> |Privacy Policy / Datenschutzerklärung| </a>
                    <a href="/Legals"> |Cookies Policy / Cookies Richtlinien|</a>
                    
                  <br/>
                  <p> </p>
                </div>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>

                <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
                <p style={{textAlign: "center"}}>Copyright © 2025 CryptoBartl</p>

            </div>
        </main>
    )
}

export default ContactUs;