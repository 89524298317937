import '../App.css'
import React, {useState} from 'react';
import { useHistory ,useLocation } from 'react-router-dom';

import {useEffect} from 'react';

import {
    HiOutlineHome,
    HiOutlineHomeModern,
    HiEnvelope,
    HiOutlineCurrencyDollar,
    HiOutlineTrophy,
    HiOutlineUserGroup,
    HiBarsArrowUp,
    HiArrowDown
  } from "react-icons/hi2";

export const NavComponent = () => {

     //for collapsing
  const [openNavigationBar, setOPenNavigationBar] = useState(true);
  const [openProjectsNav, setOpenProjectsNav] = useState(false);

  var HomePageSelected = false;
  var CryptoWorldPageSelected = false;
  var TokenFactoryPageSelected = false;
  var NFTFactoryPageSelected = false;
  var MarketplacePageSelected = false;
  var CCKPageSelected = false;
  var OldtimerPageSelected = false;
  var ContactUsPageSelected = false;

  const location = useLocation()
  var thisPath = location.pathname;
  console.log("Path" +thisPath)
  console.log("Sliced :" + thisPath.slice(-9))
    
  if (thisPath.slice(-8) == "Homepage") 
    {
        HomePageSelected = true;
        CryptoWorldPageSelected = false;
        TokenFactoryPageSelected = false;
        NFTFactoryPageSelected = false;
        MarketplacePageSelected = false;
        CCKPageSelected = false;
        OldtimerPageSelected = false;
        ContactUsPageSelected = false;
    }
  else if (thisPath.slice(-11) == "CryptoWorld")
    {
        HomePageSelected = false;
        CryptoWorldPageSelected = true;
        TokenFactoryPageSelected = false;
        NFTFactoryPageSelected = false;
        MarketplacePageSelected = false;
        CCKPageSelected = false;
        OldtimerPageSelected = false;
        ContactUsPageSelected = false;
    }
  else if (thisPath.slice(-12) == "TokenFactory")
    {
        HomePageSelected = false;
        CryptoWorldPageSelected = false;
        TokenFactoryPageSelected = true;
        NFTFactoryPageSelected = false;
        MarketplacePageSelected = false;
        CCKPageSelected = false;
        OldtimerPageSelected = false;
        ContactUsPageSelected = false;
    }
  else if (thisPath.slice(-10) == "NFTFactory")
    {
        HomePageSelected = false;
        CryptoWorldPageSelected = false;
        TokenFactoryPageSelected = false;
        NFTFactoryPageSelected = true;
        MarketplacePageSelected = false;
        CCKPageSelected = false;
        OldtimerPageSelected = false;
        ContactUsPageSelected = false;
    }
  else if (thisPath.slice(-11) == "Marketplace")
    {
        HomePageSelected = false;
        CryptoWorldPageSelected = false;
        TokenFactoryPageSelected = false;
        NFTFactoryPageSelected = false;
        MarketplacePageSelected = true;
        CCKPageSelected = false;
        OldtimerPageSelected = false;
        ContactUsPageSelected = false;
    }
  else if (thisPath.slice(-13) == "CocktailParty")
    {
        HomePageSelected = false;
        CryptoWorldPageSelected = false;
        TokenFactoryPageSelected = false;
        NFTFactoryPageSelected = false;
        MarketplacePageSelected = false;
        CCKPageSelected = true;
        OldtimerPageSelected = false;
        ContactUsPageSelected = false;
    }
  else if (thisPath.slice(-10) == "OldtimerNFT")
    {
        HomePageSelected = false;
        CryptoWorldPageSelected = false;
        TokenFactoryPageSelected = false;
        NFTFactoryPageSelected = false;
        MarketplacePageSelected = false;
        CCKPageSelected = false;
        OldtimerPageSelected = true;
        ContactUsPageSelected = false;
    }
  else if (thisPath.slice(-9) == "ContactUs")
    {
        HomePageSelected = false;
        CryptoWorldPageSelected = false;
        TokenFactoryPageSelected = false;
        NFTFactoryPageSelected = false;
        MarketplacePageSelected = false;
        CCKPageSelected = false;
        OldtimerPageSelected = false;
        ContactUsPageSelected = true;
    }

  
  const toggle_NavigationBar = () => {
    setOPenNavigationBar(!openNavigationBar);
  };
  const toggle_ProjectsNav = () => {
    setOpenProjectsNav(!openProjectsNav);
  };
    return (
       
        <div id="divHomepage" className='homepageDesign'>       
        {openNavigationBar && (
            <div className="toggle">
                <div className="topnav" id="mytopnav">
                        <br/>
                        <ul className='topnav'>
                            {HomePageSelected && (<li className='navigation_li' style={{background: "#3d0863"}}> <a href="/Homepage"> <HiOutlineHome/> Home</a></li>)}
                            {!HomePageSelected && (<li className='navigation_li'> <a href="/Homepage"> <HiOutlineHome/> Home</a></li>)}

                            {CryptoWorldPageSelected && (<li className='navigation_li' style={{background: "#3d0863"}}><a href="/CryptoWorld"> <HiOutlineCurrencyDollar/> Crypto World</a></li>)}
                            {!CryptoWorldPageSelected && (<li className='navigation_li'><a href="/CryptoWorld"> <HiOutlineCurrencyDollar/> Crypto World</a></li>)}

            	            {TokenFactoryPageSelected && (<li className='navigation_li' style={{background: "#3d0863"}}><a href="/TokenFactory"> <HiOutlineHomeModern/> Token Factory</a></li>  )}
                            {!TokenFactoryPageSelected && (<li className='navigation_li'><a href="/TokenFactory"> <HiOutlineHomeModern/> Token Factory</a></li>  )}
                            
                            {NFTFactoryPageSelected && (<li className='navigation_li' style={{background: "#3d0863"}}><a href="/NFTFactory"> <HiOutlineHomeModern/> NFT Factory</a></li>)}
                            {!NFTFactoryPageSelected && (<li className='navigation_li'><a href="/NFTFactory"> <HiOutlineHomeModern/> NFT Factory</a></li>)}
                            
                            
                            <li className='navigation_li' style={{display:"block"}}> <a onClick={toggle_ProjectsNav}>Projects  <HiArrowDown/> </a>
                                {(openProjectsNav) && (
                                  <ul className='projectnav' style={{ display:"flex", flexDirection:"column", position:"absolute"}}>
                                  <li className='navigation_li'><a href="/CryGIA_Marketplace"> <HiOutlineUserGroup/> NFT Marketplace-CryGIA</a></li>
                                  <li className='navigation_li_project' style={{display:"inline-block"}}><a href="/CryptoCocktailParty"><HiOutlineTrophy/> Cocktail Party</a></li>
                                  <li className='navigation_li_project' style={{display:"inline-block"}}><a href="/ProjectOldtimerNFT"><HiOutlineTrophy/> Oldtimer NFT</a></li>
                                  </ul>
                                )}
                            </li>

                            {ContactUsPageSelected && (<li className='navigation_li' style={{background: "#3d0863"}}><a href="/ContactUs" style={{fontStyle:"italic"}}><HiEnvelope/> Contact Us</a></li> )}
                            {!ContactUsPageSelected && (<li className='navigation_li'><a href="/ContactUs" style={{fontStyle:"italic"}}><HiEnvelope/> Contact Us</a></li> )}

                        </ul>
                  </div>
              </div>
        )}
      <button onClick={toggle_NavigationBar} className="factoryTitle"> <HiBarsArrowUp/>
        { 'Collapse NavBar'} 
      </button>
          
        </div>
    )
  };