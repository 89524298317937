import React, {useState} from 'react';
import {
    HiBarsArrowDown,
  } from "react-icons/hi2";
  import { NavComponent } from '../navigationComponents/NavComponent';


function NFTFactory()
{
   const [openTokenFactory, setOPenTokenFactory] = useState(false);
   const toggle_TokenFactory = () => {
    setOPenTokenFactory(!openTokenFactory);
  };

   return (
       <main>
           <div id="divHomepage" className='homepageDesign'>
           
           <NavComponent/>
                
           <div id="NFTFactoryDIV" >
            <h1 className="factoryTitle"> The NFT Factory </h1>

            <div style={{ borderTop: "2px solid #333 ", marginLeft: 20, marginRight: 20 }}></div>
            <div id="internalNav" className="intpageNavigation">
                  <p className='ulMidP'>DefinitionPage: </p>
                  <ul className='intpageNavigation'>
                      <li className='intpageNavigation'> <a href="#NFTGeneral" className='intpageNavigation'>#DefinitionsPage: NFT - Non Fungible Token</a></li>
                      <li className='intpageNavigation'> <a href="#WhatCanBecomeAnNFT" className='intpageNavigation'>#DefinitionsPage: What Can Become An NFT</a></li>
                      <li className='intpageNavigation'> <a href="#Prerequisites" className='intpageNavigation'>#DefinitionsPage: Prerequisites</a></li>
                      <li className='intpageNavigation'> <a href="#Copyright" className='intpageNavigation'>#DefinitionsPage: Copyright</a></li>   
                  </ul>
                  <button onClick={toggle_TokenFactory} className="factoryTitle"> <HiBarsArrowDown/>
                          {'Press to fall down the NFT Factory - DefinitionsPage'} 
                  </button>
                  <br/>
                  <br/>
                  <div style={{ borderTop: "2px solid #333 ", marginLeft: 20, marginRight: 20 }}></div>
                  <p className='ulMidP'>Projects: </p>
                  <ul> 
                      <li className='intpageNavigation'> <a href="#OldtimerCol" className='intpageNavigation'>#Project0: Oldtimer Collection</a></li>
                      <li className='intpageNavigation'> <a href="#volvoCol" className='intpageNavigation'>#Project1: Volvo Collection (upcoming)</a></li>
                  </ul>
            </div>

            {openTokenFactory && (
              <>
                <div className="factoriesDesign" id="NFTGeneral">
                  
                      <h2>NFT - Non Fungible Token</h2>
                      <p>An NFT is distingshible from the others, unique and cannot be replaced. It is also not splittable (e.g. 1 Bitcoin can be split into 2 0.5 Bitcoins, this is not possible for NFT).</p>
                      <p>From technical perspective: NFTs are simply data existing on the blockchain to which some address has ownership. NFT tokens can
                        be traded, and since all transactions on the blockchain are public, you can check the ownership history of a given NFT.
                      </p>
                      <p>Most common mistake: the owner of a given NFT is not the owner of the image or other image "attached" to the NFT, or the copyright to it. 
                        He only owns the data on the blockchain. The data can lead, as example, to an extended server where the image is stored/hosted.
                      </p>
                      <p>NFTs are mainly seen as investments, or like Pokemon-cards: something to collect and trade.</p>
                      <p>NFTs can be copied. You can create an NFT leading to an image on the same server address, but by tracking transcations, it will be clear whose
                        NFT came first. The transaction history on the blockchain is never erased, never lost!
                      </p>
                      <p>Most known marketplaces: OpenSea, Rarible, CryGIA. We store the NFTs themselves on our wallet, just like other tokens.</p>
                      <p>Information by Piotr Boron</p>

                      <p style={{fontStyle:'italic', fontSize:12}}>Main source of information:   Boron Piotr. Your own token. 2023 </p>
                </div>
                <div className="factoriesDesign" id="WhatCanBecomeAnNFT">
                  <p>You may have heard that anything can be turned into an NFT, which is completely true. 
                    However, before converting them to NFTs, one must first create a digital copy of the object in question, 
                    be it a physical structure, a flying object, or a moving stream. If the thing you want to convert is already 
                    in digital format, you won’t have any trouble; if not, you might need to take a picture of it or record it if it’s a
                    sound or in motion.
                  </p>
                </div>
                <br/>
                <br/>
                <div className="factoriesDesign" id="Prerequisites">
                  <h2>Prerequisites</h2>
                  <p>First thing you need is a Crpyto Wallet - here your NFTs will be stored. Recommanded is MetaMask. You can connect 
                    the Metamask Wallet to the BNB Chain Network.
                  </p>
                  <p>Second step is to fund your wallet - you will need a few cryptos (BNBs) to pay the transaction fees.</p>
                  
                      
                  <p style={{fontStyle:'italic', fontSize:12}}>Main source of information: 
                        <a href="https://www.investing.com/news/cryptocurrency-news/how-to-create-and-sell-nfts-a-stepbystep-guide-for-beginners-2852388?utm_source=google&utm_medium=cpc&utm_campaign=19313816760&utm_content=642156036452&utm_term=dsa-1463805041937_&GL_Ad_ID=642156036452&GL_Campaign_ID=19313816760&ISP=1&npl=1&gad_source=1&gclid=CjwKCAjww_iwBhApEiwAuG6ccDg7cSZcHpo-xhMmQ3EsbgW8cP18erW8HIjJQvIcOAyo1xt-cjYTkBoCDuwQAvD_BwE">
                          linked article from www.investing.com</a>
                      </p>
                </div>
                <br/>
                <br/>
                <div className="factoriesDesign" id="Copyright">
                  <h2>Copyright and Stealing NFT Content</h2>
                  <p>Copyright applies to NFTs just as it does to any other creative work, and using someone else's work without 
                    permission can have legal consequences. Therefore, to minimize risk, the creator should ensure that he or she
                    has the right to use the content for the NFTs.

                    The best way to protect yourself and your NFTs is to secure the right to use the content for the NFTs. 
                    In other words, the creator should either own the rights to the content or have permission from the original creator.

                    Stealing someone else's work not only puts one at risk of legal repercussions, it also harms the original creator. 
                    Therefore, it's important to respect other people's work and obtain proper permission before using anything that’s 
                    not yours to create an NFT.
                </p>
                      
                  <p style={{fontStyle:'italic', fontSize:12}}>Main source of information: 
                        <a href="https://academy.binance.com/en/articles/how-to-create-an-nft#What-Is-an-NFT?">
                          linked article from www.academy.binance.com</a>
                      </p>
                </div>
            </>
          )}
            <br/>
            <br/>
          
            <div id="OldtimerCol" className="factoriesDesign">
            <h1>PROJECT 0: Oldtimer Collection - Give your oldtimer some high-tech!</h1>
            </div>

            <div id="volvoCol" className="factoriesDesign">
            <h1>PROJECT 1: Volvo Collection - upcoming! Stay tuned!</h1>
            </div>
             
          </div> 
      
            <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
            <p style={{textAlign: "center"}}>Copyright © 2025 CryptoBartl</p>

           </div>
       </main>
   )
}

export default NFTFactory;